import React from "react";
import './App.scss';
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import {Route, Switch} from 'react-router-dom';






import CoffeeSite from './pages/CoffeeSite';










function App() {



    return (
        <div className="App">


            <Header/>
            <Switch>
                <Route path='/' exact
                       render={() => <CoffeeSite/>}/>
            </Switch>
            <Footer/>
        </div>
    );
}

export default App;
