import React from "react";
import s from "./TextBlock.module.scss";
import Button from '../../../pages/CoffeeSite/Button/Button'


const TextBlock = ({ img, textS, textB, big, button, link, Defaultlink,address }) => {
    return (
        <>
            <div className={s.miniBlock}>
                <div className={s.imgTextBlock}>
                    <img src={img} alt='icon' />
                    <p className={s.textS}> {textS}</p>
                    
                </div>
                {big && Defaultlink ?
                    <a style={{ textDecoration: "none", color: "#ffff" }} href={"tel:78124673839"}>
                        <p className={s.textB}>{textB}</p>
                    </a>
                    : ''
                 }
                 {address?
                   <p className={s.textM}>{textB}</p>:
                   ''}
                 {link ?
                  <a style={{ textDecoration: "none", color: "#ffff" }} href={link}><p className={s.textM}>{textB}</p></a> : ''}
            </div>
            {button ?
                <a style={{ textDecoration: "none" }} href={"tel:78124673839"}>
                    <Button text={'Заказать обратный звонок'}
                        fake
                        green
                    /></a> : ''
            }
        </>
    )
}

export default TextBlock