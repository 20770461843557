import React, { useEffect } from "react";
import s from './style.module.scss';
import img1 from './img/1.jpg'
import img2 from './img/2.jpg'
import img3 from './img/3.jpg'
import img4 from './img/4.jpg'
import img5 from './img/5.jpg'
import img6 from './img/6.jpg'


import HomepageBanner from './HomepageBanner/HomepageBanner'
import GreyCardsBlock from './GreyCardsBlock/GreyCardsBlock';
import BenefitsBlock from './BenefitsBlock/BenefitsBlock';



import Button from './Button/Button'
import PhotoBlock from "./PhotoBlock/PhotoBlock";

const CoffeeSite = () => {
    useEffect(() => document.title = `MEGAS.VENDING`, [])
    return (<>
        <HomepageBanner />
        <body style={{ margin: 'auto' }} >

            <main style={{ overflow: 'hidden', }}>


                <section className={s.section}>
                    <div className={`${s.shabuWrap} ${s.shabu1200}`}>
                        <p id="solution" className={s.defaultTitle}>Наши Решения</p>
                    </div>

                    <div className={s.wrapper}>
                        <div className={s.card}>
                            <div className={s.imgblock}>

                                <img className={s.pimage} src={img1} title='product1' />
                            </div>

                            <div className={s.ptextblock}>
                                <p className={s.ptitle}>Кофе-поинт</p>
                                <p className={s.pdescr}>Кофейня самообслуживания оснащена кофемашиной, премиальными сортами итальянского кофе, тумбой, сиропами, стаканчиками для кофе, средством оплаты банковскими картами и приложением с программами лояльности.</p>
                                <ul className={s.dotlist}>
                                    <li>Современный внешний вид;</li>
                                    <li>Сиропы, сахар — всё в свободном
                                        доступе</li>
                                    <li>Крышки и трубочки позволяют
                                        легко взять кофе с собой</li>
                                    <li>Бумажные экологичные стаканчики
                                        250 мл.</li>
                                    <li>Кофе премиум класса.</li>

                                </ul>
                            </div>

                        </div>


                        <div className={s.card}>
                            <div className={s.imgblock}>

                                <img className={s.pimageslim} src={img2} title='product2' />
                            </div>

                            <div className={s.ptextblock}>
                                <p className={s.ptitle}>Кофейный автомат</p>
                                <p className={s.pdescr}>Формула идеального кофе – это наши современные высокотехнологичные автоматы от известного итальянского производителя Necta в сочетании с премиальными ингредиентами и нашим опытным обслуживанием.</p>
                                <ul className={s.dotlist}>
                                    <li>Готовит свежемолотый кофе;</li>
                                    <li>Более ярко раскрывают вкус кофе;
                                    </li>
                                    <li>Ассортимент до 20 наименований, различные варианты обьема напитков;
                                    </li>
                                    <li>Высокая производительность;

                                    </li>
                                    <li>Высокая производительность;
                                    </li>
                                    <li>Управляется дистанционно;
                                    </li>
                                    <li>Оснащен безналичной оплатой;
                                    </li>
                                    <li>Современный, стильный дизайн.
                                    </li>
                                </ul>
                            </div>

                        </div>






                        <div className={s.card}>
                            <div className={s.imgblock}>

                                <img className={s.pimage} src={img3} title='product3' />
                            </div>

                            <div className={s.ptextblock}>
                                <p className={s.ptitle}>Кофемашина</p>
                                <p className={s.pdescr}>Формула идеального кофе – это наши современные высокотехнологичные настольные кофемашины от известного итальянского производителя Necta в сочетании с премиальными ингредиентами и нашим опытным обслуживанием.</p>
                                <ul className={s.dotlist}>
                                    <li>                            Готовит свежемолотый кофе;
                                    </li>
                                    <li>Более ярко раскрывают вкус кофе;
                                    </li>
                                    <li>Компактные размеры;
                                    </li>
                                    <li>Высокая производительность;
                                    </li>
                                    <li>Управляется дистанционно;
                                    </li>
                                    <li>Оснащен безналичной оплатой;
                                    </li>
                                    <li>Современный, стильный дизайн.
                                    </li>

                                </ul>
                            </div>

                        </div>


                        <div className={s.card}>
                            <div className={s.imgblock}>

                                <img className={s.pimageslimextra} src={img4} title='product4' />
                            </div>

                            <div className={s.ptextblock}>
                                <p className={s.ptitle}>Умный холодильник</p>
                                <p className={s.pdescr}>Компактное и современное решения для продажи готовой свежей еды, прохладительных напитков и соков.</p>
                                <ul className={s.dotlist}>
                                    <li>Cвежий ассортимент продукции в зависимости от ваших пожеланий;</li>
                                    <li>Безопасные бесконтактные покупки без кассира;</li>
                                    <li>Быстрое обслуживание без очередей — мобильное приложение или терминал;</li>
                                    <li> Гибкая система лояльности и бонусов;</li>
                                    <li>Круглосуточный доступ 24/7.</li>


                                </ul>
                            </div>

                        </div>


                        <div className={s.card}>
                            <div className={s.imgblock}>

                                <img className={s.pimage} src={img5} title='product5' />
                            </div>

                            <div className={s.ptextblock}>
                                <p className={s.ptitle}>Микромаркет</p>
                                <p className={s.pdescr}>Микромаркет — продажа готовой свежей еды и товаров в офисе, бизнес-центре и учреждении.</p>
                                <ul className={s.dotlist}>
                                    <li>Cвежий ассортимент продукции в зависимости от ваших пожеланий;</li>
                                    <li>Безопасные бесконтактные покупки без кассира;</li>
                                    <li> Быстрое обслуживание без очередей — мобильное приложение или терминал; </li>
                                    <li>Гибкая система лояльности и бонусов; </li>
                                    <li>Круглосуточный доступ 24/7.</li>


                                </ul>
                            </div>

                        </div>


                        <div className={s.card}>
                            <div className={s.imgblock}>

                                <img className={s.pimageslim} src={img6} title='product6' />
                            </div>

                            <div className={s.ptextblock}>
                                <p className={s.ptitle}>Снековый автомат</p>
                                <p className={s.pdescr}>Микромаркет — продажа готовой свежей еды и товаров в офисе, бизнес-центре и учреждении.</p>
                                <ul className={s.dotlist}>
                                    <li>До 50 уникальных позиций в автомате;</li>
                                    <li>Управляется дистанционно;</li>
                                    <li>Оснащен безналичной оплатой;</li>
                                    <li>Современный, стильный дизайн;</li>
                                    <li>Продажа сэндвичей и готовых обедов;</li>

                                </ul>
                            </div>

                        </div>


                        <div className={s.card}>
                            <div className={s.imgblock}>

                                <img className={s.pimage} src={img1} title='product7' />
                            </div>

                            <div className={s.ptextblock}>
                                <p className={s.ptitle}>Комбинированный
                                    автомат</p>
                                <p className={s.pdescr}>Комбинированный автомат включает в себя все преимущества кофеного и снекового автомата</p>
                                <ul className={s.dotlist}>
                                    <li>До 16 горячих напитков;

                                    </li>
                                    <li>До 50 уникальных позиций
                                        в снековом автомате;</li>
                                    <li>Управляется дистанционно;
                                    </li>
                                    <li>Оснащен безналичной оплатой;
                                    </li>
                                    <li>Современный, стильный дизайн;
                                    </li>
                                    <li>Продажа сэндвичей и готовых
                                        обедов.</li>

                                </ul>
                            </div>

                        </div>

                        {/* greeen card */}
                        <div className={s.cardExtra}>
                            <div className={s.pgreentextblock}>
                                <p className={s.greenTitle}><span>Попробуйте</span> любое<br />из наших решений  </p>
                                <p className={s.greenText}>В  данном подзаголовке может быть стоит написать<br />
                                    про условия или выгоду от приобретения вендингового автомата</p>
                                <Button text={'Установить наши решения'}
                                    fake
                                    style={{ background: 'linear-gradient(107.01deg, #FFFFFF 3.46%, #B3D5B4 86.44%)' }}
                                />
                            </div>

                        </div>
                    </div>
                </section>

                <BenefitsBlock />
                <GreyCardsBlock />
                <PhotoBlock />




            </main>
        </body>
    </>
    )
};

export default CoffeeSite;




















