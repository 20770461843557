import React from "react";
import Slider from "react-slick";

import s from './PhotoSlider.module.scss'
import styled from "styled-components";

import ph1 from '../../../pages/CoffeeSite/img/ph1.jpg'
import ph2 from '../../../pages/CoffeeSite/img/ph2.jpg'
import ph3 from '../../../pages/CoffeeSite/img/ph3.jpg'

import ph4 from '../../../pages/CoffeeSite/img/ph4.png'
import ph5 from '../../../pages/CoffeeSite/img/ph5.png'


import {NextArrow, PrevArrow} from "../SliderArrows/sliderArrowButtons";


 const SliderStyles = styled.div`
 .slick-slide {
   padding-top: 20px;
   padding-left:20px;
   padding-right:20px;
   width: 600px;
  } 
  .slick-track {
    display: flex;
    align-items: center;
  }
  .slick-center{
    transform: scale(1.08);
  }
  
 `





const PhotoSlider = () => {
  var settings = {
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    centerMode: true,
    autoplay: true,
  autoplaySpeed: 2000,
     centerPadding: '60px',
    variableWidth: true,
    nextArrow: <NextArrow positionStyles={{
            bottom: "-75px",
            right: "50%",
            transform: "translateX(120%)"
        }}/>,
        prevArrow: <PrevArrow positionStyles={{
            bottom: "-75px",
            left: "50%",
            transform: "translateX(-120%)"
        }}/>,
     responsive:[
      {
        breakpoint:700,
        settings:{
          slidesToShow: 1,
         }
      }
    ]
  };
  return (
     <div className={s.container}>
       <SliderStyles>
    <Slider {...settings}>
      
        <div className={s.card}>
         <img src={ph2} title='photo2'/>
         </div>
 
         <div className={s.card}>
         <img  src={ph1} title='photo1'/>
         </div>
 
         <div className={s.card}>
         <img src={ph3} title='photo3'/>
         </div>

         <div className={s.card}>
         <img src={ph4} title='photo4'/>
         </div>
 
         <div className={s.card}>
         <img src={ph5} title='photo5'/>
         </div>
 
         {/* <div className={s.card}>
         <img src={ph3} title='photo3'/>
         </div> */}
         </Slider> 
         </SliderStyles>
      </div>
  );
}

export default PhotoSlider