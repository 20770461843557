import React from 'react'
import s from './HomepageBanner.module.scss'

import Button from '../Button/Button'

import guy from '../img/guy.png'


const HomepageBanner = () => {



    return (
        <section className={s.section}>
            <div className={s.wrapper}>
                <div className={s.container}>
                    <div className={s.leftBlock}>
                        <p className={s.title}>Сытый сотрудник – эффективный сотрудник</p>
                        <p className={s.text}>Обеспечим завтраки, обеды, перекусы в офисе. &shy; Умные холодильники, минимаркеты, снековые автоматы</p>
                        <a href='#'>
                            <button className={s.button}>Повысить эффективность
                                сотрудников</button>
                        </a>

                    </div>
                    <div className={s.rightBlock}>
                        <img src={guy} title='theGuy' />
                    </div>
                </div>
            </div>
        </section>

    )
}

export default HomepageBanner
