import React from 'react'
import s from './BenefitsBlock.module.scss'

import BenefitSlider from '../../../components/Sliders/benefitSlider/BenefitSlider'


const BenefitsBlock = () => {



    return (
        <section className={s.section}>
        <div className={s.wrapper}>
            <div className={s.container}>
            <div className={s.title}>
                    <p id="benefits">Наши преимущества</p>
                </div> 
                   <BenefitSlider/>
                         </div> 
         </div>
         </section>

    )
}

export default BenefitsBlock
