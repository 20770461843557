import React from "react";
import Slider from "react-slick";

import s from './BenefitSlider.module.scss'

import icon1 from '../../../pages/CoffeeSite/img/ic1.svg'
import icon2 from '../../../pages/CoffeeSite/img/ic2.svg'
import icon3 from '../../../pages/CoffeeSite/img/ic3.svg'
import icon4 from '../../../pages/CoffeeSite/img/ic4.png'
import icon5 from '../../../pages/CoffeeSite/img/ic5.png'
import icon6 from '../../../pages/CoffeeSite/img/ic6.png'
import icon7 from '../../../pages/CoffeeSite/img/ic7.png'
import icon8 from '../../../pages/CoffeeSite/img/ic8.png'

import {NextArrow, PrevArrow} from "../SliderArrowsWhite/sliderArrowButtons";






const BenefitSlider = () => {
  var settings = {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: <NextArrow positionStyles={{
      top: "40%",
      right: "0",
      transform: "translateX(120%)"
  }}/>,
  prevArrow: <PrevArrow positionStyles={{
      top: "40%",
      left: "0",
      transform: "translateX(-120%)"
  }}/>,
    // nextArrow: '<img src="../../../pages/CoffeeSite/img/prev.png" alt="prev">',
    // prevArrow: '<img src="../../../pages/CoffeeSite/img/next.png" alt="next">',
    responsive:[
      {
        breakpoint:700,
        settings:{
          slidesToShow: 1,
          nextArrow: <NextArrow positionStyles={{
            bottom: "15%",
            right: "0",
            transform: "translateX(-150%)"
        }}/>,
        prevArrow: <PrevArrow positionStyles={{
            bottom: "15%",
            left: "0",
            transform: "translateX(150%)"
        }}/>,
         }
      }
    ]
  };
  return (
    <Slider {...settings}>
      <div>
        <div className={s.card}>
          <div className={s.icon}><img src={icon1} title='Benefit' /></div>
          <p className={s.title}>Ассортимент</p>
          <p className={s.descr}>Современные
            высокотехнологичные автоматы, оборудование</p>
        </div>
      </div>



      <div><div className={s.card}>
        <div className={s.icon}><img src={icon2} title='Benefit' /></div>
        <p className={s.title}>Оборудование</p>
        <p className={s.descr}>Современные
          высокотехнологичные автоматы, оборудование</p>
      </div></div>



      <div>
        <div className={s.card}>
          <div className={s.icon}><img src={icon3} title='Benefit' /></div>
          <p className={s.title}>Системы</p>
          <p className={s.descr}>Интеллектуальные IT системы управления запасами ингредиентов и товаров в автоматах</p>
        </div>
      </div>



      <div>
        <div className={s.card}>
          <div className={s.icon}><img style={{transform: 'scale(0.8)'}}src={icon4} title='Benefit' /></div>
          <p className={s.title}>Логистика</p>
          <p className={s.descr}>Безупречная автоматизированная логистика</p>
        </div>
      </div>

      <div>
        <div className={s.card}>
          <div className={s.icon}><img style={{transform: 'scale(0.7)'}} src={icon5}  title='Benefit' /></div>
          <p className={s.title}>Лояльность</p>
          <p className={s.descr}>Индивидуальные бонусные и скидочные программы с использованием инновационных систем, возможность интегрировать системы лояльности под потребности вашего бизнеса. Мобильное приложение для сотрудников и клиентов</p>
        </div>
      </div>

      <div>
        <div className={s.card}>
          <div className={s.icon}><img style={{transform: 'scale(0.7)'}} src={icon6} title='Benefit' /></div>
          <p className={s.title}>Бренд</p>
          <p className={s.descr}>Современный дизайн оборудования – возможность индивидуального брендирования</p>
        </div>
      </div>

      <div>
        <div className={s.card}>
          <div className={s.icon}><img style={{transform: 'scale(0.7)'}} src={icon7} title='Benefit' /></div>
          <p className={s.title}>Call центр</p>
          <p className={s.descr}>Круглосуточная информационная поддержка – call центр </p>
        </div>
      </div>

      <div>
        <div className={s.card}>
          <div className={s.icon}><img style={{transform: 'scale(0.7)'}} src={icon8} title='Benefit' /></div>
          <p className={s.title}>Управление</p>
          <p className={s.descr}>Онлайн управление обслуживанием автоматов – высокие стандарты качества обслуживания, регулярная сан. обработка оборудования</p>
        </div>
      </div>

      
    </Slider>
  );
}

export default BenefitSlider