import React from "react";
import s from "./Footer.module.scss";
import img1 from '../../pages/CoffeeSite/img/miniIcon/telephone.png'
import img2 from '../../pages/CoffeeSite/img/miniIcon/pin.png'
import img3 from '../../pages/CoffeeSite/img/miniIcon/mail.png'



import TextBlock from "./TextBlock/TextBlock";

const Footer = () => {



    return (<>
        <div id='footer' className={s.wrapper} >
            <div className={s.container}>
                <div className={s.title}>
                    <p id="contacts">Контактная информация</p>
                </div>
                <div className={s.row}>
                    <div className={s.col1}>
                        <TextBlock img={img1}
                            textS='Телефон'
                            textB='+7 (812) 467-38-39'
                            big
                            Defaultlink
                        />
                        <TextBlock img={img2}
                            textS='Адрес'
                            textB='г. Санкт-Петербург, ул. Симонова, 15
                    График работы: пн-пт 08:00-17:00'
                            button
                            address
                        />
                    </div>
                    <div className={s.col2}>
                        <TextBlock img={img3}
                            textS='По вопросам сотрудничества, закупки
                    ингредиентов и продукции:'
                            textB='zakupki@megasvending.ru'
                            link='mailto:zakupki@megasvending.ru'

                        />
                        <TextBlock img={img3}
                            textS='Связь с директором:'
                            textB='feedback@megasvending.ru'
                            link='mailto:feedback@megasvending.ru'

                        /> <TextBlock img={img3}
                            textS='По вопросам установки оборудования:'
                            textB='razvitie@megasvending.ru'
                            link='mailto:razvitie@megasvending.ru'

                        />
                        <p></p>

                    </div>
                    <div className={s.col3}>
                        <div className={s.filter} style={{ width: "100%" }} ><iframe width="100%" height="300" frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0" src="https://maps.google.com/maps?width=100%25&amp;height=300&amp;hl=en&amp;q=%D1%83%D0%BB.%20%D0%A1%D0%B8%D0%BC%D0%BE%D0%BD%D0%BE%D0%B2%D0%B0,%2015+(MEGAS.VENDING)&amp;t=&amp;z=16&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"><a href="https://www.gps.ie/truck-gps/">delivery trucks gps</a></iframe></div>
                    </div>

                </div>
            </div>

        </div>
    </>
    );
};

export default Footer;
