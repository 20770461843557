import React from "react";


import s from "./sliderArrowButtons.module.scss";
import rightArrowImg from "../../../pages/CoffeeSite/img/next.png";
import leftArrowImg from "../../../pages/CoffeeSite/img/prev.png"

export function NextArrow({style, positionStyles, onClick}) {
    return (
        <div
            className={s.nextArrow}
            style={{...style, backgroundImage: 'url(' + rightArrowImg + ')', ...positionStyles}}
            onClick={onClick}
        />
    );
}

export function PrevArrow({style, positionStyles, onClick}) {
    return (
        <div
            className={s.prevArrow}
            style={{...style, backgroundImage: 'url(' + leftArrowImg + ')', ...positionStyles}}
            onClick={onClick}
        />
    );
}