import React from 'react'
import s from './GreyCardsBlock.module.scss'

import img1 from '../img/1gr.png'
import img2 from '../img/2gr.png'
import img3 from '../img/3gr.png'
import img4 from '../img/4gr.png'
import img5 from '../img/5gr.png'
import img6 from '../img/6gr.png'
import img7 from '../img/7gr.png'

const GreyCardsBlock = () => {

    return (
        <section className={s.section}>
            <div className={s.wrapper}>
                <div className={s.textBlock}>
                    <p  className={s.title}>
                        Кофе поинт
                    </p>
                    <p><span className={s.premium}>Премиум решения</span> для бизнес-центров, офисов, автосалонов.<br /> Вкусный итальянский кофе, гибкая программа лояльности, современный внешний вид.</p>
                </div>
                <div className={s.greeenCircleHalf}>
                    <div className={s.wrapperCard}>
                        <div className={s.imgblock}>
                            <img   src={img1} title='product1' />
                        </div>

                        <div className={s.cardTextBlock}>

                            <div className={s.cardTitle}>
                                <p>Кофе-поинт Standart</p>
                            </div>
                            <div className={s.descrBlock}>
                                <div className={s.leftBlock}>
                                    <span>Технические <br />
                                        характеристики</span>
                                    <ul className={s.dotlist}>
                                        <li>Высота 2200 мм</li>
                                        <li>Ширина 800 мм</li>
                                        <li>Глубина 760 мм</li>
                                        <li>Класс энергопотребления А+</li>
                                    </ul>
                                </div>
                                <div className={s.rightBlock}>
                                    <span >Ассортимент горячих напитков на стойке самообслуживания:</span>

                                    <div className={s.listBlock}>
                                        <div className={s.list1}>
                                            <ul className={s.dotlist}>
                                                <li>Эспрессо</li>
                                                <li>Американо</li>
                                                <li>Капучино</li>
                                                <li> Латте</li>
                                                <li>Мокачино</li>
                                            </ul></div>

                                        <div className={s.list2}>
                                            <ul className={s.dotlist}>
                                                <li>Сливочный Шоколад</li>
                                                <li>Шоколад</li>
                                                <li>Ванильный Латте</li>
                                                <li>Ванильный Капучино</li>
                                            </ul></div>
                                    </div>
                                    <div className={s.extraBlock}>
                                        <p >Объем стаканчика: 250 мл.</p>
                                    </div>
                                </div>
                            </div>

                        </div>


                    </div>
                </div>

                <div className={s.wrapperCard}>
                    {/* <div className={s.card}>
                   
                    </div> */}
                    <div className={s.imgBlockS}>
                        <img className={s.pimage}  src={img2} title='product2' />
                    </div>

                    <div className={s.cardTextBlock}>

                        <div className={s.cardTitle}>
                            <p>Кофе-поинт Maxi</p>
                        </div>
                        <div className={s.descrBlock}>
                            <div className={s.leftBlock}>
                                <span>Технические <br />
                                    характеристики</span>
                                <ul className={s.dotlist}>
                                    <li>Высота 2200 мм</li>
                                    <li>Ширина 800 мм</li>
                                    <li>Глубина 760 мм</li>
                                    <li>Класс энергопотребления А+</li>
                                </ul>
                            </div>
                            <div className={s.rightBlock}>
                                <span>Ассортимент горячих напитков на стойке самообслуживания:</span>

                                <div className={s.listBlock}>
                                    <div className={s.list1}>
                                        <ul className={s.dotlist}>
                                            <li>Эспрессо</li>
                                            <li>Американо</li>
                                            <li>Капучино</li>
                                            <li> Латте</li>
                                            <li>Мокачино</li>
                                        </ul></div>

                                    <div className={s.list2}>
                                        <ul className={s.dotlist}>
                                            <li>Сливочный Шоколад</li>
                                            <li>Шоколад</li>
                                            <li>Ванильный Латте</li>
                                            <li>Ванильный Капучино</li>
                                        </ul></div>
                                </div>
                                <div className={s.extraBlock}>
                                    <p >Объем стаканчика: 250 мл.</p>
                                </div>


                            </div>
                        </div>

                    </div>


                </div>

                <div className={s.wrapperCard}>
                    {/* <div className={s.card}>
                   
                    </div> */}
                    <div className={s.imgBlockS}>
                        <img   src={img3} title='product3' />
                    </div>

                    <div className={s.cardTextBlock}>

                        <div className={s.cardTitle}>
                            <p>Кофе-Поинт Combo</p>
                        </div>
                        <div className={s.descrBlock}>
                            <div className={s.leftBlock}>
                                <span>Технические <br />
                                    характеристики</span>
                                <ul className={s.dotlist}>
                                    <li>Высота 2200 мм</li>
                                    <li>Ширина 1860 мм</li>
                                    <li>Глубина 760 мм</li>
                                    <li>Класс энергопотребления А+</li>
                                </ul>
                            </div>
                            <div className={s.rightBlock}>
                                <span>Ассортимент горячих напитков на стойке самообслуживания:</span>

                                <div className={s.listBlock}>
                                    <div className={s.list1}>
                                        <ul className={s.dotlist}>
                                            <li>Эспрессо</li>
                                            <li>Американо</li>
                                            <li>Капучино</li>
                                            <li> Латте</li>
                                            <li>Мокачино</li>
                                        </ul>
                                    </div>


                                    <div className={s.list2}>
                                        <ul className={s.dotlist}>
                                            <li>Сливочный Шоколад</li>
                                            <li>Шоколад</li>
                                            <li>Ванильный Латте</li>
                                            <li>Ванильный Капучино</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className={s.extraBlock}>
                                    <p>Объем стаканчика: 250 мл.</p>
                                    <p>До 42 видов снеков
                                        и напитков</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>



                <div className={s.textBlock}>
                    <p className={s.title}>
                        Вендинговые автоматы
                    </p>
                </div>
                <div className={s.wrapperCard}>
                    <div className={s.imgblock}>
                        <img   src={img4} title='product4' />
                    </div>
                    <div className={s.cardTextBlock}>
                        <div className={`${s.cardTitle} ${s.specialTitle}`}>
                            <p >Кофейный автомат</p>
                        </div>
                        <div className={s.descrSecondBlockSpecial}>
                            <p>Современные модели кофейных автоматов от надежного итальянского производителя: KikkoMax ToGo, Opera ToGo, Canto Touch. Формат Кофе с собой
                                .</p>
                            <p><span>3 объема стакана на выбор:</span> 165 мл, 250 мл, 360 мл
                            </p>
                            <p>Гибкие системы лояльности для сотрудников: скидки, бесплатные напитки, акции.  </p>
                        </div>

                    </div>


                </div>


                <div className={s.wrapperCard}>
                    {/* <div className={s.card}>
                   
                    </div> */}
                    <div className={s.imgblock}>
                        <img   src={img5} title='product5' />
                    </div>

                    <div className={s.cardTextBlock}>

                    <div className={`${s.cardTitle} ${s.specialTitle}`}>
                            <p>Снековый автомат</p>
                        </div>
                        <div className={s.descrSecondBlockSpecial}>
                            <p>Лучшее оборудование: FAS FAST 1050, FAS FAST 900, Necta JAZZ, Crane Merchant Media Touch <br /> Подберем самое подходящее решение снекового автомата для вашего предприятия.
                            </p>
                        </div>

                    </div>


                </div>


                <div className={s.wrapperWide}>
                    {/* <div className={s.card}>
                   
                    </div> */}
                    <div className={s.imgBlockWide}>
                        <img   src={img6} title='product6' />
                    </div>

                    <div className={`${s.cardTextBlock} ${s.cardTextBlockWide}`}>

                    <div className={`${s.cardTitle} ${s.specialTitle}`}>
                            <p>Микромаркет</p>
                        </div>
                        <div className={s.descrSecondBlockSpecial}>
                            <p>Современное и компактное решение для продажи готовых блюд и напитков.
                            </p>
                        </div>

                    </div>


                </div>

                <div className={s.wrapperCard}>
                    {/* <div className={s.card}>
                   
                    </div> */}
                    <div className={s.imgblockTightPhoto}>
                        <img   src={img7} title='product7' />
                    </div>

                    <div className={s.cardTextBlock}>

                    <div className={`${s.cardTitle} ${s.specialTitle}`}>
                            <p>Умный холодильник</p>
                        </div>
                        <div className={s.descrSecondBlockSpecial}>
                            <p>Современное и компактное решение для продажи готовых блюд и напитков.
                            </p>
                        </div>

                    </div>


                </div>




            </div>



        </section >
    )
}

export default GreyCardsBlock






















