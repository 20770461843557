import React from "react";
import s from "./Button.module.scss";
import { NavLink } from "react-router-dom";
// import { Link as Scroll } from "react-scroll";


const Button = ({
    text = 'Кнопка',
    onClick,
    style,
    href,
    fake,
    green
    // anchor = false
}) => {
    if (fake) {
        return (
            <div className={s.container}>
                 <div style={style} className={!green ? s.button : s.greenButton}>
                    <p>
                        {text}
                    </p>
                    </div>
            </div>
          
        )
    }
    if (onClick) {
        return <div style={style} onClick={onClick} className={s.button}>
            <span>
                {text}
            </span>
        </div>
    }
    if (href) {
        return (
            <a style={style} target="_blank" className={s.button} href={href}>
           <div style={style} className={s.button}>
                    <span>
                        {text}
                    </span>
                    </div>
        </a>)
    } 
};

export default Button;

