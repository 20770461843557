import React from 'react'
import s from './PhotoBlock.module.scss'

import PhotoSlider from '../../../components/Sliders/PhotoSlider/PhotoSlider'


const PhotoBlock = () => {



    return (
        <section className={s.section}>
        <div className={s.wrapper}>
            <div className={s.container}>
            <div className={s.title}>
                    <p id="confidence">Примеры установок</p>
                </div> 
                   <PhotoSlider/>
                         </div> 
         </div>
         </section>

    )
}

export default PhotoBlock
