import React from "react";
import s from "./Header.module.scss";
import logo from "../../pages/CoffeeSite/img/logo.svg"
import {NavLink} from "react-router-dom";
import logoPhone from "../../pages/CoffeeSite/img/phone.png";


const Link = ({title, style, icon, link = '#', extraClass = null, onClickHandler, href = ''}) => {
    if (href) return <a href={href} onClick={onClickHandler ? onClickHandler : null} style={style}
                        className={s.link + ' ' + extraClass}>
        {icon && <img src={icon} alt={title}/>}
        {title && <div>{title}</div>}
    </a>

    return <NavLink onClick={onClickHandler ? onClickHandler : null} style={style} to={link}
                    className={s.link + ' ' + extraClass}>
        {icon && <img src={icon} alt={title}/>}
        {title && <div>{title}</div>}
    </NavLink>
};

const Header = () => {

    let [scrolledFromTop, setScrolledFromTop] = React.useState(0);
    let [popupOpened, setPopupOpened] = React.useState(false);

    const listenToScroll = () => {
        const scroll = document.body.scrollTop || document.documentElement.scrollTop;
        setScrolledFromTop(scroll)
    };

    React.useEffect(() => {
        window.addEventListener('scroll', listenToScroll);
        return () => window.removeEventListener('scroll', listenToScroll)
    }, []);


    const handleLinkClick = (e) => {
        e.preventDefault()
    }
    

    return (<>

            <div className={scrolledFromTop > window.innerHeight / 1.3 ? `${s.wrapper} ${s.minified}` : s.wrapper}>
                 <div className={s.container}>
                     <div className={ `${s.content} ${s.minified}`}>
                    <div className={s.logo}>
                            <NavLink to={'/'}><img src={logo} alt="Прометей"/></NavLink>
                        </div>
                        <div className={s.leftBlock}>
                            <Link title={"Наши Решения"} style={{color:'#13A438'}} onClickHandler={(e) => {
                                handleLinkClick(e);
                                document.querySelector('#solution').scrollIntoView({behavior: 'smooth', block: 'start'})
                            }}/>
                            <Link title={"Преимущества"} style={{color:'white'}}  onClickHandler={(e) => {
                                handleLinkClick(e);
                                document.querySelector('#benefits').scrollIntoView({behavior: 'smooth', block: 'start'})
                            }}/>
                             <Link title={"Нам доверяют"} style={{color:'white'}} onClickHandler={(e) => {
                                handleLinkClick(e);
                                document.querySelector('#confidence').scrollIntoView({behavior: 'smooth', block: 'start'})
                            }}/>
                             <Link title={"Контакты"} style={{color:'white'}} onClickHandler={(e) => {
                                handleLinkClick(e);
                                document.querySelector('#contacts').scrollIntoView({behavior: 'smooth', block: 'start'})
                            }}/>
                        </div>
 
                        <div className={s.rightBlock}>
                           
                            <a style={{textDecoration: "none"}} href={"tel:78124673839"}>
                            <div className={s.button} onClick={() => setPopupOpened(true)}>{
                                window.matchMedia('(max-width: 1200px)').matches
                                    ? <img style={{
                                        width: "55%",
                                        filter: 'invert(1)'
                                    }} src={logoPhone} alt=""/>
                                    : 'Позвонить'
                            }</div>
                            </a>

                           

                        </div>

                    </div>
                </div>

 
            </div>

        </>
    )
};

export default Header;

